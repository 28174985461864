:root { 
--blue_50_30:#ebf3ff30; 
--gray_800_01:#454545; 
--black_900_3f:#0000003f; 
--pink_A100:#ff6baa; 
--black_900_01:#020202; 
--deep_orange_300:#ff7361; 
--light_blue_A700:#0091fa; 
--blue_gray_900_02:#333333; 
--blue_gray_900_01:#1f2038; 
--pink_500:#f01e64; 
--indigo_800_01:#2a3480; 
--blue_gray_900:#1f2e35; 
--black_900_03:#080606; 
--black_900_02:#0d0f13; 
--gray_200_47:#f0f0f047; 
--black_900_04:#080606; 
--teal_A700:#07cf9f; 
--gray_600:#787878; 
--gray_400:#bdbec2; 
--gray_800:#4f4f4f; 
--blue_gray_500:#469194; 
--indigo_600_01:#2b52a1; 
--indigo_900_01:#2a2a75; 
--indigo_900_02:#23056a; 
--indigo_800:#2a3580; 
--white_A700:#ffffff; 
--light_blue_A700_01:#0090ff; 
--indigo_600:#2b5aa9; 
--teal_400_01:#339999; 
--cyan_A400:#00d0ff; 
--blue_gray_50:#f1f2f2; 
--light_blue_A400:#00bcff; 
--blue_gray_100_00:#d9d9d900; 
--indigo_A200:#4176fa; 
--teal_200:#75cad1; 
--gray_50:#f5faff; 
--green_A400:#00cf7f; 
--black_900_1e:#0000001e; 
--light_blue_800:#007ab9; 
--teal_400:#32989b; 
--pink_A200:#fa4696; 
--black_900:#000000; 
--deep_orange_600:#ff4d12; 
--yellow_900:#de8d0c; 
--deep_purple_A100:#b659ff; 
--deep_purple_A700:#4c0ce8; 
--teal_A700_01:#06ce9e; 
--gray_500:#ab9777; 
--blue_gray_900_04:#00243a; 
--gray_900:#1e1e1e; 
--blue_gray_900_03:#00243a; 
--gray_300:#dddddd; 
--gray_300_01:#dfdfdf; 
--gray_100:#f2f4f3; 
--yellow_900_01:#cd8410; 
--white_A700_00:#ffffff00; 
--cyan_100:#bcf5f7; 
--indigo_900:#292974; 
--indigo_700:#2b4896; 
}